.left-align-area{
    width: calc(100% - 500px - 500px);
    margin-left: 500px;
    margin-right: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
}
.left-align-txt{
    width: 350px;
}
.left-align-txt-mb{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 60px;
}
.left-align-img{
    width: 800px;
}
.right-align-txt{
    display: flex;
    flex-direction: column;

    width: 200px;
    margin-right: 50px;
}
.right-align-img{
    width:800px
}
.last-area{
    margin-bottom: 100px;
}
