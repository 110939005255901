/* Scroll container */
.scroll-container {
    scroll-snap-type: y mandatory; /* y축 방향으로 강제 스냅 */
    overflow-y: scroll; /* y축 스크롤 활성화 */
    height: 100vh; /* 한 화면의 높이로 설정 */
    scroll-behavior: smooth; /* 부드러운 스크롤 */
}

/* 각 스크롤 섹션 */
.scroll-section {
    scroll-snap-align: start; /* 각 섹션의 시작 부분에 맞추어 스냅 */
    height: 100vh; /* 각 섹션이 전체 화면을 차지 */
}
.main-pg {
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-banner {
    min-width: 1000px;
    /* margin-top: 50px; */
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100vh - 60px - 50px - 50px);
    align-items: center;
    justify-content: space-between;
}
.main-banner-mb {
    min-width: 300px;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}
.home-img-mb{
    width: calc(100% - 48px - 48px);
    margin-left: 48px;
    margin-right: 48px;
    margin-top: 48px;
    border-radius: 20px;
    margin-right: 200px;
    overflow: hidden;
    /* min-width: 300px; */
    height: 400px;
}
.home-img-mb>img {
    object-fit: contain;
    height: 800px;
    transform: translateX(-570px);
    /* 왼쪽으로 200px 이동 */
}

.main-banner-txts {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 450px;
    margin-left: 200px;
}
.main-banner-txts-mb{
    width: calc(100% - 48px - 48px);
    margin-left: 48px;
    margin-right: 48px;
    margin-top: 48px;
}

.banner-txt-top {
    font-weight: 500;
    color: #AA8B3B;
    font-size: 45px;
}
.banner-txt-top-mb{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;
    color: #AA8B3B;
    font-size: 30px;
    margin-bottom: 30px;
}

.banner-txt-bottom {
    font-weight: 500;
    color: #888888;
    font-size: 28px;

}
.banner-txt-bottom-mb{
    font-weight: 500;
    color: #888888;
    font-size: 20px;
    margin-bottom: 30px;
}
.show-all{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.house-cards {
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100% - 200px - 200px);
    height: calc(100vh - 60px - 100px);
    background-color: #DAC5A7;
    padding-top: 50px;
    /* padding: 50px 0; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.house-cards-mb {
    width: 100%;
    background-color: #DAC5A7;
}

.house-cards>ul {
    display: flex;
    flex-direction: row;
    gap: 30px;
    list-style: none;
    margin: 0;

}

.house-cards-mb>ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    list-style: none;
padding: 0;
margin: 0;
    padding-top: 60px;
    padding-bottom: 60px;
}


.house-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* 수평 및 수직 중앙 정렬 */
    height: 260px;
}

.sample-house-img {
    object-fit: cover;
    height: 100%;
}
.sample-house-img-mb {
    width: 300px;
    object-fit: cover;
    height: 100%;
}


.sample-house-info {
    color: #666666;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 30px - 30px);
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
}

.sample-house-title {
    color: black;
    font-size: 20px;
    margin-bottom: 30px;
}

.sample-more {
    display: flex;
    margin-top: 30px;
    margin-left: -10px;
}

.home-img {
    border-radius: 20px;
    margin-right: 200px;
    overflow: hidden;
    width: 1000px;
    min-width: 600px;
    height: 600px;
}

.home-img>img {
    object-fit: contain;
    height: 1000px;
    transform: translateX(-500px);
    /* 왼쪽으로 200px 이동 */
}

.remodeling-img {
    border-radius: 20px;
    margin-right: 50px;
    overflow: hidden;
    width: 700px;
    min-width: 700px;
    height: 600px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.remodeling-img-mb{
    border-radius: 20px;
    margin-right: 50px;
    overflow: hidden;
    width: calc(100% - 30px - 30px);
    margin-left: 30px;
    margin-right: 30px;
    min-width: 300px;
    height: 600px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.remodeling-img>img {
    object-fit: contain;
    width: 700px;
    /* height: 1000px; */
}
.remodeling-img-mb>img {
    object-fit: contain;
    width: 600px;
    /* height: 1000px; */
}
.money-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    height: 100vh;
}
.money-more-area-wrapper{
    width: calc(100% - 300px - 200px);
    margin-left: 300px;
    margin-right: 200px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.money-more-area-wrapper-mb{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.money-more-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;


}
.money-more-area-mb {
    width: (100% - 30px - 30px);
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
font-size: 20px;
color: #888888; 

}
.money-area-mb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    width: 100%;
  margin-top: 30px;
}

.money-title {
    text-align: center;
    font-size: 50px;
    margin-bottom: 20px;
}
.money-more-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
    color: #888888;
}
.money-info {
    text-align: center;
    width:360px;
    font-size: 26px;
    color: #888888;
}
.money-info-mb{
    text-align: center;
    width: 300px;
    font-size: 20px;
    color: #888888; 
}

.money-more {
    margin-top: 20px;
}

.remodeling-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 200px - 200px);
    height: 100vh;
    min-width: 1400px;
    margin-left: 200px;
    margin-right: 200px;
    background-color: #F5E9C7;
}
.remodeling-area-mb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 300px;

    background-color: #F5E9C7;
}
.remodeling-before-after-mb{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

}
.remodeling-before-after
.pro
 {
    width: calc(100% - 200px - 200px);
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
   
    background-color: white;
}
.pro-mb{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.remodeling-before-after{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 200px;
    margin-right: 200px;
    padding-top: 100px;
    padding-bottom: 100px;
gap: 60px;
}

.before-area {
    width: calc(100% - 30px);
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* margin-left: 200px; */
    gap: 30px;
}
.before-area-mb {
    width: calc(100% - 30px - 30px);
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 60px;
}

.remodeling-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 60px;
}

.remodeling-before-img {
    object-fit: contain;
    width: 100%;
}

.remodeling-after-img {
    object-fit: contain;
    width: 100%;
    /* transform: translateY(-100px); */
}

.after-area {
    width: 800px;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #AA8B3B;
    /* margin-right: 200px; */
    gap: 30px;
}
.pro{
    display: flex;
    align-items: center;
    justify-content: center;
  height: 485px;
  width: 100%;
}
.pro-row {
font-size: 50px;
}
.pro-row-mb{
    font-size: 36px;
}
.photos-grid{
    padding-top: 50px;
    padding-bottom: 50px;
width: calc(100% - 400px);
margin-left: 200px;
margin-right: 200px;
display: flex;
flex-direction: column;
gap: 20px;
}
.pro-img-mb{
height: 360px;
transform: translateX(-50px);
}
.photos-grid-mb{
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px - 30px);
    gap: 30px;
    padding-top: 80px;
    margin-left: 30px;
    margin-right: 30px;
}
.photo-grid-container{
    width: 900px;
    height: 400px;
    border-radius: 30px;
    overflow: hidden;
}
.mb-homestaging-img{
    height: 500px;
    transform: translateX(-200px);
}
.photo-grid-container-mb{
    width: 100%;
    height: 500px;
    border-radius: 30px;
    overflow: hidden;
}
.md-photo{
    width: 620px;
}
.sm-photo{
    width: 430px;
}
.big-img{
    transform: translateY(-120px);
}
.md-img{
    transform: translateY(-200px);
}
.sm-img{
    /* transform: translateY(-50px); */
}
.photo-row{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.complete-house{
    width: calc(100% - 200px - 200px);
    margin-top: 300px;
    margin-left: 200px;
    margin-right: 200px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.complete-house-mb{
    width: 100%;
 margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.complete-txt{
    font-size: 45px;
}
.complete-txt-mb{
    width: calc(100% - 30px - 30px);
    margin-left: 30px;
    margin-right: 30px;
    font-size: 36px;

}
.txt-indent{
    text-indent: 50px;
}
.footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    width: calc(100% - 200px - 200px);
    margin-left: 200px;
    margin-right: 200px;
}
.sns{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.link {
    text-decoration: underline; /* 밑줄 설정 */
    cursor: pointer; /* 마우스를 링크 모양으로 설정 */
}

/* 필요에 따라 hover 스타일도 추가 가능 */
.link:hover {
    color: darkblue; /* 호버 시 색상 변경 */
    text-decoration: underline; /* 호버 시 밑줄 유지 */
}
.footer-mb{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 60px;
}
